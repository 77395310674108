import React, { useEffect, useState } from 'react';
import { CircularProgress, makeStyles, Select } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DatePicker from '../../UI/DatePicker/DatePicker';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { useUser } from '../../../../providers/UserContext'
import { useTimesheetType } from '../../../../providers/TimesheetTypeContext';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const TimesheetOptions = (props) => {

    const { users, userLoading, getUsers } = useUser();
    const { timesheetType } = useTimesheetType();

    const [open, setOpen] = useState(false);
    const [openTypeFilter, setOpenTypeFilter] = useState(false);
    const classes = useStyles();

    //Search Autocomplete Values
    const [typedValue, setTypedValue] = useState(JSON.parse(sessionStorage.getItem("timesheetOptionsSearchValue")) || '')
    const [typedTimesheetTypeValue, setTypedTimesheetTypeValue] = useState(JSON.parse(sessionStorage.getItem("timesheetTypeSearchValue")) || '')

    useEffect(() => {
        getUsers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    
                    <Grid item sm={5}>
                        <Typography>Search By</Typography>
                    </Grid>
                     
                    <Grid item sm={7}>
                        <Select
                            name="type"
                            value={props.searchState.type}
                            onChange={props.searchHandler}
                            native>
                            <option value="timesheetID">ID</option>
                            <option value="employee">Employee</option>
                        </Select>
                    </Grid> 


                    {props.searchState.type === "timesheetID" ?
                        <Grid item sm={12}>
                        
                            <TextField
                                label="Search"
                                size="small"
                                value={props.searchState.value}
                                onChange={props.searchHandler}
                                name="value"
                                InputProps={props.searchState.value ? {
                                    endAdornment: <IconButton onClick={props.clearSearch}><ClearIcon /></IconButton>
                                } : null} />
                        </Grid>
                    :           
                    <Grid item sm={12}>
                        <Autocomplete
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            loading={userLoading}
                            label="Search"
                            id="autocomplete"
                            autoComplete
                            autoHighlight
                            inputValue={typedValue}
                            onInputChange={(event, newValue) => { setTypedValue(newValue); }}
                            value={props.searchValue}
                            onChange={(event, newValue) => { props.searchValueChanged(newValue); sessionStorage.setItem("timesheetAutoComplete", JSON.stringify(newValue)); }}
                            options={users.filter(x=> x.employment?.active).map((user) => user.displayName ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                            getOptionSelected={(option, value) => option?.displayName === value?.displayName}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Employee"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}

                        />
                     </Grid>
                    }
                </Grid>
            </Grid>

            {/*  timesheetType auto complete here */}
                <Grid item sm={12}>
                    <Autocomplete
                        open={openTypeFilter}
                        onOpen={() => {
                            setOpenTypeFilter(true);
                        }}
                        onClose={() => {
                            setOpenTypeFilter(false);
                        }}
                        loading={userLoading}
                        label="Search"
                        id="timesheetTypeAutocomplete"
                        autoComplete
                        autoHighlight
                        inputValue={typedTimesheetTypeValue}
                        onInputChange={(event, newValue) => { setTypedTimesheetTypeValue(newValue); props.typeSearchValueChanged(newValue);  }}
                        value={props.typeSearchValue}
                        onChange={(event, newValue) => { props.typeSearchValueChanged(newValue); sessionStorage.setItem("timesheetTypeAutoComplete", JSON.stringify(newValue)); }}
                        options={timesheetType}
                        //getOptionSelected={(option, value) => option === value}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Type"
                                variant="outlined"
                            />
                        )}

                    />
                </Grid>


            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="Start Date"
                            value={props.startDate}
                            onChange={props.startDateHandler}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="End Date"
                            value={props.endDate}
                            onChange={props.endDateHandler}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend" className={classes.formLabel}>Include:</FormLabel>
                            <FormGroup>
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.unsubmitted} onChange={props.handler} name="unsubmitted" />}
                                    label="Unsubmitted"
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.submitted} onChange={props.handler} name="submitted" />}
                                    label="Submitted"
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.approved} onChange={props.handler} name="approved" />}
                                    label="Approved"
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.payrolled} onChange={props.handler} name="payrolled" />}
                                    label="Payrolled"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend" className={classes.formLabel}>Status</FormLabel>
                            <FormGroup>
                                {/*<FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.unseen} onChange={props.handler} name="unseen" />}
                                    label="Unseen"
                                />*/}
                                {/*<FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.viewed} onChange={props.handler} name="viewed" />}
                                    label="Viewed"
                                />*/}
                                <FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.printed} onChange={props.handler} name="printed" />}
                                    label="Printed"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.notprinted} onChange={props.handler} name="notprinted" />}
                                    label="Not Printed"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.both} onChange={props.handler} name="both" />}
                                    label="Both"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sm={12}>
                {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
            </Grid>
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate </Button>
            </Grid>

            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" size={"small"} style={{marginBottom:'1px'}} fullWidth disabled={props.loading} onClick={props.handlePrint} > Print Table </Button>
                <Button variant="contained" size={"small"} style={{marginBottom:'1px'}} fullWidth disabled={props.loading} onClick={props.handlePrintPayroll} > Print Payroll Report </Button>
                <Button variant="contained" size={"small"} fullWidth disabled={props.loading} onClick={props.handlePrintTotal} > Print Total Hours </Button>
            </Grid>

        </Grid>
    )
}

export default TimesheetOptions;