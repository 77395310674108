import React, { useState, useEffect } from 'react'
import { Modal, Typography, Button, Paper, Fade, Backdrop, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const AreYouSure = (props) => {

    const [open, setOpen] = useState(props.open);
    const [yesDisabled, setYesDisabled] = useState(props.timer);

    const handleClose = () => {
        props.setOpen(false);
        setOpen(false);
        setYesDisabled(props.timer);
    }


    useEffect(() => {
        setOpen(props.open);
        if(open || props.open) {timer();}
        //eslint-disable-next-line
    }, [props.open, yesDisabled, open])


   const timer = () => {
    if(!props.open || !open) {
        setYesDisabled(props.timer);
        return;
    }
    if(yesDisabled > 0 && open && props.open){
        setTimeout(() => {
            if(!open || !props.open) return;
            setYesDisabled(yesDisabled - 1);
        }
        , 1000);
    } else return;
    }

    
    return (
        <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}>
                <Fade in={open}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography align='center'>{props.customMessage ? props.customMessage : 'Are you sure?'}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography align='center'>Please allow a few seconds for Yes to become available.</Typography>
                            </Grid>

                            <Grid item>
                                <Button startIcon={<CheckIcon/>} style={{marginRight:'10px'}} disabled={yesDisabled > 0} variant='contained' onClick={() => {props.passedFunction(); handleClose();}}>Yes {yesDisabled > 0 ? '('+yesDisabled+')' : null}</Button>
                                <Button startIcon={<CloseIcon/>} style={{color:'white',backgroundColor:'red'}} variant='contained' onClick={handleClose}>No</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
    )
}

export default AreYouSure;