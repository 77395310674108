import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import Skeleton from '@material-ui/lab/Skeleton';
import { useCustomer } from '../../../providers/CustomerContext';
import { useUser } from '../../../providers/UserContext';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'
import _ from 'lodash';
import moment from 'moment';

import InvoiceForm from '../../components/Invoice/InvoiceForm';

const InvoiceNew = (props) => {

    //const classes = useStyles();

    const { customers, getCustomers } = useCustomer();
    const { getUsers, users } = useUser();
    const { units, getUnits } = useUnitDescription();

    const [ticketEntryData, setTicketEntryData] = useState([])
    const [internalLoading, setInternalLoading] = useState(true);
    const [generatingInvoice, setGeneratingInvoice] = useState(false)

    const defaultCurrentInvoice = {
      combinedMaterialCartageSales: false,
      //reportDetail: 'detail',
      invoiceDate: moment().valueOf(),
      //invoiceNumber: '',
      secondaryNumberType: '',
      secndaryNumberValue: '',
      invoiceTerms: 'Net 30 Days',
      attention: '',
      taxOptions: 'calculate',
      gstRate: '5.00',
      rstRate: '0.00',
      useSystemLogo: true,
      cartageOnlyFeeType: 'fuelSurcharge',
      cartageOnlyFee: '0.00',
      cartageAndMaterialFeeType: 'carbonFee',
      cartageAndMaterialFee: '0.00',
      //invoicedToCustomer: false,
    }
    const [currentInvoice, setCurrentInvoice] = useState({})

    const defaultInvoiceFilters = {
      //reportScope: 'all',
      startDate: moment().subtract(1, 'week'),
      endDate: moment(),
      //findByTicketNumber: false,
      ticketFinderOn: false,  //doenst seem to do anything
    }
    const [invoiceFilter, setInvoiceFilter] = useState("invoiceFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("invoiceFilter")) : defaultInvoiceFilters)
    //const [invoiceType, setInvoiceType] = useState("invoiceType" in sessionStorage ? JSON.parse(sessionStorage.getItem("invoiceType")) : 'rate')
    const [selectedRow, setSelectedRow] = useState([]) 
    const [filteredListData, setFilteredListData] = useState([])
    const [ratesData, setRatesData] = useState([])

    //get intital data, and sets default values
    useEffect(() => {
      if(users.length === 0){ getUsers() }
      if(customers.length === 0){ getCustomers() }
      if(units.length === 0){ getUnits() }

      firestore.collection('shopSettings').doc("taxes").get()
      .then((doc) => {
        let taxData = doc.data()

        firestore.collection('rates').get()
        .then(querySnapshot => {
            const response = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,     
            }
            })
            setRatesData(response)
            setInvoiceDefaults(taxData)
            setInternalLoading(false);  
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    //get data based off invoiceFilter.startDate, invoiceFilter.endDate and invoice 
    useEffect(() => {
      firestore.collection('deliveries')
        .where("invoiceVerified", "==", true)
        .where("date", ">=", moment(invoiceFilter.startDate).format("YYYY-MM-DD"))
        .where("date", "<=", moment(invoiceFilter.endDate).format("YYYY-MM-DD")).get()
        .then(querySnapshot => {
            let ticketentryResponse = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,     
            }
            })
            //filter where invoicePayDate exists
            ticketentryResponse = ticketentryResponse.filter(x=> x.invoicePayDate === null || x.invoicePayDate === undefined )
            setTicketEntryData(ticketentryResponse);
        })
        .catch(e => {
            setInternalLoading(false);
            console.log(e.message);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceFilter.startDate, invoiceFilter.endDate]);

    //filters ticket data for list
    useEffect(() => {
      let container = _.cloneDeep(ticketEntryData)
    
      let orderByField = invoiceFilter.findByTicketNumber ? 'ticket' : 'customerId'
      container = container.filter(x=> x[orderByField] !== undefined && x[orderByField] !== null && x[orderByField] !== '')

      if(!invoiceFilter.findByTicketNumber){
         container = [...container.reduce((map, obj) => map.has(obj.customerId) ? map : map.set(obj.customerId, obj), new Map()).values()]; 
      }

      //data for ticket display
      let alternativeDisplayData = invoiceFilter.findByTicketNumber ? null : customers
      let alterativeDisplayUID = invoiceFilter.findByTicketNumber ? null : 'customerId'
      const uid = "docId"

      container.map((item, index) => {
         let displayData = !_.isEmpty(alternativeDisplayData) ? alternativeDisplayData.find(x=> x[uid] === item[alterativeDisplayUID]) : item;

          let reducedDisplay = invoiceFilter.findByTicketNumber 
            ? displayData?.ticket ? displayData?.ticket : '' 
            : `${displayData?.name ? displayData?.name : ''} ${displayData?.streetNumber ? displayData?.streetNumber : ''} ${displayData?.streetName ? displayData?.streetName : ''}`

          item.multiListDataTitle = reducedDisplay;
      })

      console.log('setFilteredListData', container)
      setFilteredListData(container)
    }, [ticketEntryData, invoiceFilter])

    //sets the default values before page is loaded
    const setInvoiceDefaults = (taxData) => {
      if("currentInvoice" in sessionStorage){
        setCurrentInvoice(JSON.parse(sessionStorage.getItem("currentInvoice")))
      }
      else if(taxData.salesTax.GST && taxData.fuelSurcharge && taxData.carbonFee){
        let invoiceContainer = _.cloneDeep(defaultCurrentInvoice)
        invoiceContainer.gstRate = (taxData.salesTax.GST * 100).toFixed(2);
        invoiceContainer.cartageOnlyFee = (taxData.fuelSurcharge * 100).toFixed(2);
        invoiceContainer.cartageAndMaterialFee = (taxData.carbonFee * 100).toFixed(2);
        setCurrentInvoice(invoiceContainer)
      }
      else{
        throw new Error('Tax data was not found when applying default invoice additional data.')
      }
    }

    const handlePreviewPDF = async () => {
      setGeneratingInvoice(true)
      let ticketDataContainer = []

      //get only the tickets selected if by ticketNumber or jobNumber, else all uninvoiced from that contact
      if(invoiceFilter.findByTicketNumber ){
        ticketDataContainer = selectedRow.map((docId) => {return ticketEntryData.find(x=> x.docId === docId) })
      }
      else{
        selectedRow.forEach((row) => {
          let currentCustomer = ticketEntryData.find(x=> x.docId === row)?.customerId
          let newData = ticketEntryData.filter(x=> x.customerId === currentCustomer)
          ticketDataContainer = ticketDataContainer.concat(newData)
        })
      }

      let customersContainer = ticketDataContainer.map((ticket) => { return customers.find(x=> x.docId === ticket.customerId) })
      let unitContainer = [];
      for (const ticket of ticketDataContainer) { unitContainer.push(...await retrieveUnitData(ticket.units)) }
      let ratesList = [...ticketDataContainer.reduce((map, obj) => (map.has(obj.rate) || obj.rate == null)  ? map : map.set(obj.rate, obj.rate), new Map()).values()];
      let ratesContainer = ratesList.map((docId) => {  return ratesData.find(x=> x.docId === docId) })

      props.history.push({
        pathname: "/dashboard/invoicing/printing/",
        state: { 
          ticketData: JSON.stringify(ticketDataContainer), 
          invoiceDetails: JSON.stringify(currentInvoice), 
          invoiceFilter: JSON.stringify(invoiceFilter),
          //invoiceType: JSON.stringify(invoiceType),
          equipment: JSON.stringify(unitContainer),
          customers: JSON.stringify(customersContainer),
          rates: JSON.stringify(ratesContainer),
          //customerJobs: JSON.stringify(customerJobContainer),
        },
      });
    }

    const retrieveUnitData = async (unitDocIdArray) => {
      let unitsContainer = []

      for (const docId of unitDocIdArray) {
        let findUnit = units.find(x=> x.docId === docId)
        if(!_.isUndefined(findUnit)){ unitsContainer.push(findUnit); }

        else{
          await firestore.collection('units').where("docId", "==", docId).get()
          .then(querySnapshot => {
            const unitResponse = querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                'docId': doc.id,
              };
            });

            unitsContainer.push({...unitResponse[0]}); 
          })
        }
      }
      return unitsContainer;
    }

  return (
    <Grid>
        {internalLoading 
        ?
          <div style={{marginTop: '95px',}}>
            <Skeleton variant='rect' width={'85vw'} height={'55vh'} style={{margin: 'auto'}}></Skeleton>
          </div>
        :      
            <Grid>
                  <InvoiceForm 
                    units={units}
                    users={users}
                    customers={customers}
                    internalLoading={internalLoading}
                    currentInvoice={currentInvoice}
                    setCurrentInvoice={setCurrentInvoice}
                    invoiceFilter={invoiceFilter}
                    setInvoiceFilter={setInvoiceFilter}
                    ticketEntryData={ticketEntryData}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    generatingInvoice={generatingInvoice}

                    filteredListData={filteredListData}
                    //invoiceType={invoiceType}
                    //setInvoiceType={setInvoiceType}
                    handlePreviewPDF={handlePreviewPDF}

                    ratesData={ratesData}

                    //filteredJobsArray={filteredJobsArray}
                  />

            </Grid>
        }

    </Grid>
  )
}

export default withRouter(InvoiceNew);