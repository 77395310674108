import React, { useState } from 'react';
import Aux from '../../../hoc/AuxComponent';
import CustomerForm from '../../components/Reports/Customers/CustomerForm'
import { firestore } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const CustomerNew = (props) => {

  const initialCustomer = {
    city: '',
    email: '',
    name: '',
    address: [''],
    postalCode: '',
    province: 'MB',
    website: '',
    streetName: '',
    streetNumber: '',
    jobNumber: '',
    isActive: true,
    isParent: false,
    contacts: [],
    archived: false,
    deliveryAddress: {  
      streetName: '',
      streetNumber: '',
      city: '',
      postalCode: '',
      province: 'MB',
    }
  }

  const [customer, setCustomer] = useState(initialCustomer);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  
  //Handles onChange of CustomerForm Inputs
  const textHandler = (event, parentContainer) => {
    if(parentContainer){ setCustomer({ ...customer, [parentContainer]: {...customer[parentContainer], [event.target.name]: event.target.value } }); }
    else{ setCustomer({ ...customer, [event.target.name]: event.target.value }); }

    // switch (event.target.name) {
    //   //Handle value as array 
    //   case "address":
    //     //Dont call splice in the hook set method to conform to hook rules
    //     let address = customer.address.value;
    //     address.splice(event.target.id, 1, event.target.value)
    //     setCustomer({ ...customer, address: { ...customer["address"], value: address } });
    //     break;
    //   default:
    //     setCustomer({ ...customer, [event.target.name]: { ...customer[event.target.name], value: event.target.value } });
    //     break;
    // }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    createCustomer();
  }

  // const addAddress = () => {
  //   let address = customer.address['value'];
  //   address.push('');
  //   setCustomer({ ...customer, address: { ...customer['address'], value: address } })
  // }

  // const removeAddress = (index) => {
  //   let address = customer.address['value'];
  //   address.splice(index, 1)
  //   setCustomer({ ...customer, address: { ...customer['address'], value: address } })
  // }

  const createCustomer = () => {
    let newCustomer = _.cloneDeep(customer)

    // for (const [key, value] of Object.entries(customer)) {
    //   newCustomer[key] = value.value;
    // }

    //newCustomer.archived = false;

    setSubmitting(true);
    firestore.collection('customers').add(newCustomer)
      .then((docRef) => {
        //set docId
        let docId = docRef.id;
        firestore.collection('customers').doc(docId).update({ docId: docId });
        
        setCustomer(initialCustomer);
        setSubmitting(false);
        setError('');
        setSuccess(true);
        //timeout to allow success message to show
        setTimeout(() => {
          setSuccess(false);
        }, 3000)
      }).catch((e) => {
        setSubmitting(false);
        setError("Unable to create customer, please try again");
      });
  }

  return (
    <Aux>
      <CustomerForm
        customer={customer} 
        setCustomer={setCustomer}
        textHandler={textHandler}
        handleSubmit={handleSubmit} 
        submitting={submitting}
        //addAddress={addAddress} 
        //removeAddress={removeAddress}
        error={error} 
        success={success} 
      />
    </Aux>
  )
}

export default withRouter(CustomerNew);