import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {Chip, Slide, FormControl, Select, TextField, LinearProgress, withStyles} from '@material-ui/core'
import {Skeleton, Alert} from '@material-ui/lab'
import { firestore } from '../../../../firebase/firebase'
import firebase from 'firebase/compat/app'
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAuth } from '../../../../providers/AuthContext';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import moment from 'moment';


const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      border: '1px solid white',
    },
    colorPrimary: {
      backgroundColor: 'grey',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FFBF00',
    },
  }))(LinearProgress);

const TimesheetHeader = (props) => {
    const [open, setOpen] = React.useState(false);
    const [splitOpen, setSplitOpen] = React.useState(false);
    const [approveOpen, setApproveOpen] = React.useState(false);
    const [approvePayrollOpen, setApprovePayrollOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [setting, setSetting] = React.useState('')
    const [splitting, setSplitting] = React.useState(false)
    const [splitProgress, setSplitProgress] = React.useState(0)
    const [splitDescription, setSplitDescription] = React.useState('')
    const [deleteReason, setDeleteReason] = React.useState('')
    const { roles, currentUser, userInfo } = useAuth();
    const history = useHistory();
    const path = "/dashboard/reporting/timesheets/printing/?docId=" + props.timesheetData.docId
    const handlePrint = () => {
        //could wait for this to return, but holds up loading, user wont see until they return
        firestore.collection('timesheets').doc(props.timesheetData.docId).update({ 'timesheetStatus': 'printed' })
        history.push(path)
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseSplit = () => {
        setSplitOpen(false);
    };

    const handleOpenSplit = () => {
        setSplitOpen(true)
    }
    
    const handleAlert = (style) =>{
        setSetting(style)
        setAlertOpen(true);
        setTimeout(() => {setAlertOpen(false);setSetting('');}, 4 * 1000);
    }

    const handleClickApproveOpen = () => {
        setApproveOpen(true);
    };

    const handleClickApproveClose = () => {
        setApproveOpen(false);
    };

    const handleClickApprovePayrollOpen = () => {
        setApprovePayrollOpen(true);
    };

    const handleClickApprovePayrollClose = () => {
        setApprovePayrollOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEmployeeClick = () => {
        let docId
        firestore.collection('users').where('uuid', '==', props.timesheetData.uid).get().then((docs) => docs.forEach((doc) => {
            docId = doc.id
        })).then(() => {
            props.history.push({
                pathname: '/dashboard/users/form',
                          search: "?" + new URLSearchParams({ docId: docId }).toString(),
                          tag:'timesheet',
                          timesheetDoc: props.timesheetData.docId,

            })
        })

    }

    const handleDeleteClick = () => {
        firestore.collection('notifications').add({
            'type': 'delete',
            'message': 'Timesheet #'+props.timesheetData.timesheetID+' has been deleted by '+userInfo.firstName + ' ' + userInfo.lastName,
            'userEmail': currentUser.email,
            'userName': userInfo.firstName + ' ' + userInfo.lastName,
            'timestamp': new Date(),
            'reason': deleteReason,
        })
        firestore.collection('timesheets').doc(props.timesheetData.docId).delete().then(() => {
            firestore.collection('jobs').where('timesheetID', '==', props.timesheetData.docId).get().then((docs) => docs.forEach((doc) => firestore.collection('jobs').doc(doc.id).delete()))
            firestore.collection('checklists').where('timesheetID', '==', props.timesheetData.docId).get().then((docs) => docs.forEach((doc) => firestore.collection('checklists').doc(doc.id).delete()))
            firestore.collection('deliveries').where('timesheetID', '==', props.timesheetData.docId).get().then((docs) => docs.forEach((doc) => firestore.collection('deliveries').doc(doc.id).delete()))
            firestore.collection('units').where('timesheetID', '==', props.timesheetData.docId).get().then((docs) => docs.forEach((doc) => firestore.collection('units').doc(doc.id).delete().then(() => {
                firestore.collection('defectReports').where('unitId', '==', doc.id).get().then((docs) => docs.forEach((doc) => firestore.collection('defectReports').doc(doc.id).delete()))
            })))
            console.log("Timesheet successfully deleted!");
            handleClose();
            //remove timesheet from sessionstorage
            let timesheets = JSON.parse(sessionStorage.getItem('timesheetData'))
            timesheets.length>0 ? timesheets = timesheets.filter((timesheet) => timesheet.docId !== props.timesheetData.docId) : timesheets=[]
            sessionStorage.setItem('timesheetData', JSON.stringify(timesheets))
            props.history.replace({pathname: '/dashboard/reporting/'})
        }).catch((error) => {
            console.error("Error removing timesheet: ", error);
        });
    }

    const handleApproveClick = () => {
        props.updateTimesheetField({ 'approved': true });
        props.setSaveState({...props.saveState, approved: true})
        console.log("Timesheet successfully approved!");
        setApproveOpen(false);
        firestore.collection('timesheets').doc(props.timesheetData.docId)
        .update({
            'approved': true,
            'payrolled': false,
        })
        .then(() => {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', props.timesheetData.timesheetType).orderBy('timesheetID')
            .get()
            .then((querySnapshot) => {
                let data = querySnapshot.docs.map((doc) => doc.data())
                props.setTimesheetLoading(true)
                props.history.push({
                    pathname: '/dashboard/reporting/timesheets',
                    search: "?" + new URLSearchParams({ docId: data[0].docId }).toString(),
                    timesheetList: props.location.timesheetList && props.location.timesheetList.filter((timesheet) => timesheet.docId !== props.timesheetData.docId),
                })
            })
        })
        .catch((e) => {console.log(e);});
        handleAlert()
    }

    const handleApproveClickPayroll = () => {
        props.updateTimesheetField({ 'payrolled': true });
        props.setSaveState({...props.saveState, payrolled: true})
        console.log("Timesheet successfully approved!");
        setApprovePayrollOpen(false);
        firestore.collection('timesheets').doc(props.timesheetData.docId)
        .update({
            'approved': true,
            'payrolled': true,
        })
        .then(() => {
            firestore.collection('timesheets').where('payrolled', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', props.timesheetData.timesheetType).orderBy('timesheetID')
            .get()
            .then((querySnapshot) => {
                let data = querySnapshot.docs.map((doc) => doc.data())
                props.setTimesheetLoading(true)
                props.history.push({
                    pathname: '/dashboard/reporting/timesheets',
                    search: "?" + new URLSearchParams({ docId: data[0].docId }).toString(),
                    timesheetList: props.location.timesheetList && props.location.timesheetList.filter((timesheet) => timesheet.docId !== props.timesheetData.docId),
                })
            })
        })
        .catch((e) => {console.log(e);});
        handleAlert()
    }

    const handleUnapproveClick = () => {
        props.updateTimesheetField({ 'approved': false });
        props.setSaveState({...props.saveState, approved: false})
        console.log("Timesheet successfully unapproved!");
        setApproveOpen(false);
        firestore.collection('timesheets').doc(props.timesheetData.docId)
        .update({
            'approved': false,
            'payrolled': false,
        })
        .then(() => {
        })
        .catch((e) => {console.log(e);});
        handleAlert('unapprove')
    }

    const handleUnapproveClickPayroll = () => {
        props.updateTimesheetField({ 'payrolled': false });
        props.setSaveState({...props.saveState, payrolled: false})
        console.log("Timesheet successfully unapproved!");
        setApprovePayrollOpen(false);
        firestore.collection('timesheets').doc(props.timesheetData.docId)
        .update({
            'payrolled': false,
        })
        .then(() => {
        })
        .catch((e) => {console.log(e);});
        handleAlert('unapprove')
    }

    const handleSplitTimesheet = async () => {
        try {
            setSplitting(true)
            setSplitProgress(1);
            console.log("Splitting timesheet");
            setSplitDescription('Copying data to new timesheet...');
            let currentData = props.timesheetData;
            const newTimesheet = {
                ...currentData,
                inTime: moment(currentData.outTime).startOf('day').toDate().valueOf(),
                date: moment(currentData.outTime).startOf('day').toDate().valueOf(),
                docId: '',
                timesheetID: 0,
            };
            setSplitProgress(10);
            // Select a random shard and increment its count
            setSplitDescription('Selecting a random shard...');
            const shardIndex = Math.floor(Math.random() * 10);
            setSplitProgress(15);
            setSplitDescription('Generating new timesheet ID...');
            await firestore.collection('shards').doc(shardIndex.toString()).update({
                count: firebase.firestore.FieldValue.increment(1)
            });
            setSplitProgress(17);
            // Get the updated shard counts and calculate the newID
            const shardDocs = await firestore.collection('shards').get();
            const newID = shardDocs.docs.reduce((shardCount, doc) => shardCount + doc.data().count, 0);
            console.log("New Timesheet ID: ", newID);
            setSplitProgress(20);
            newTimesheet.timesheetID = newID;
            // Add new timesheet and update its docId
            const docRef = await firestore.collection('timesheets').add(newTimesheet);
            setSplitDescription('New Timesheet Created, updating docID...');
            setSplitProgress(25);
            const newDocId = docRef.id;
            await firestore.collection('timesheets').doc(newDocId).update({ 'docId': newDocId });
            setSplitProgress(27);
            newTimesheet.docId = newDocId;
            console.log("New Timesheet DOCID: ", newDocId);
            setSplitProgress(28);
            // Update the outTime of the current timesheet
            setSplitDescription('Updating outTime of current timesheet...');
            await firestore.collection('timesheets').doc(currentData.docId).update({ 'outTime': moment(currentData.inTime).endOf('day').toDate().valueOf() });
            console.log("Current Timesheet outTime updated");
            setSplitProgress(30);
            // Copy related data (jobs, checklists, deliveries, units) to the new timesheet
            const collections = ['jobs', 'checklists', 'deliveries', 'units'];
            console.log("Copying jobs, checklists, deliveries and units to new timesheet...");
            for (let collection of collections) {
                const relatedDocs = await firestore.collection(collection).where('timesheetID', '==', currentData.docId).get();
                const batch = firestore.batch();
                relatedDocs.forEach((doc) => {
                    const newDocRef = firestore.collection(collection).doc(); // Create a new document ref
                    batch.set(newDocRef, { ...doc.data(), 'timesheetID': newTimesheet.docId });
                });
                await batch.commit(); // Execute batch writes for each collection
            }
            setSplitProgress(50);
            setSplitDescription('Copying new timesheet to related work orders...');
            //for workOrders that have either this timesheet docId in timesheetID or in the timesheets array of the workOrder then add the new timesheet docId to the timesheets array and add the new timesheets uid to the secondaryTechs array
            const workOrders = await firestore.collection('workOrders').where('timesheets', 'array-contains', currentData.docId).get();
            const batch2 = firestore.batch();
            workOrders.forEach((doc) => {
                batch2.update(doc.ref, { 'timesheets': firebase.firestore.FieldValue.arrayUnion(newTimesheet.docId), 'secondaryTechs': firebase.firestore.FieldValue.arrayUnion(newTimesheet.uid),
                    'secondaryTimesheets': firebase.firestore.FieldValue.arrayUnion(newTimesheet.uid+"__"+newTimesheet.docId)
                 });
            });
            await batch2.commit();
            setSplitProgress(60);
            const workOrders2 = await firestore.collection('workOrders').where('timesheetID', '==', currentData.docId).get();
            const batch3 = firestore.batch();
            workOrders2.forEach((doc) => {
                batch3.update(doc.ref, { 'timesheets': firebase.firestore.FieldValue.arrayUnion(newTimesheet.docId), 'secondaryTechs': firebase.firestore.FieldValue.arrayUnion(newTimesheet.uid),'secondaryTimesheets': firebase.firestore.FieldValue.arrayUnion(newTimesheet.uid+"__"+newTimesheet.docId) });
            });
            await batch3.commit();
            console.log("Related data copied to new timesheet");
            setSplitProgress(70);
            setSplitDescription('Writing notes to timesheets about the split....');

            // Add notes about the timesheet split
            const notes = [
                {
                    timesheetId: currentData.docId,
                    note: `This timesheet was split into two timesheets, this means it was automatically set to Skip Lunch.\nThe 2nd timesheet is Timesheet #${newTimesheet.timesheetID}`,
                },
                {
                    timesheetId: newTimesheet.docId,
                    note: `This timesheet was split from Timesheet #${currentData.timesheetID}`,
                }
            ];
            setSplitProgress(80);
            for (let note of notes) {
                await firestore.collection('timesheets').doc(note.timesheetId).collection('notes').add({
                    'note': note.note,
                    'timestamp': new Date(),
                    'uid': currentUser.uid,
                });
            }
            setSplitProgress(90);
            console.log("Notes added to timesheets");
            setSplitDescription('Splitting complete!');
            setSplitProgress(100);
            setTimeout(() => {
                //set current timesheet to the updated old timesheet
                props.updateTimesheetField({ 'outTime': moment(currentData.inTime).endOf('day').toDate().valueOf(), 'skipLunch': 'pending' });
                props.confirmSave();
                handleCloseSplit();
                setSplitting(false);
            }, 2000);

            
            
        } catch (error) {
            console.error("Error splitting timesheet: ", error);
        }
    };

    return (
        <React.Fragment>
            < Grid container direction='row' spacing={2} justifyContent='space-between' >
            <Dialog
                open={splitOpen}
                //onClose={handleCloseSplit}
            >
                <DialogTitle id="alert-dialog-title">{"Split Timesheet?"}</DialogTitle>
                <DialogContent>
                    {!splitting?<DialogContentText id="alert-dialog-description">
                        Are you sure you want to split this timesheet into 2 timesheets?
                    </DialogContentText>:null}
                    {splitting?<DialogContentText id="alert-dialog-description">
                        Please wait... Splitting Timesheet...
                    </DialogContentText>:null}
                    {splitting?<BorderLinearProgress variant="determinate" value={splitProgress} />:null}
                    {splitting?<DialogContentText id="alert-dialog-description"> {splitDescription} </DialogContentText>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleSplitTimesheet()} disabled={splitting} variant="contained" color="primary" autoFocus>
                        Split
                    </Button>
                    <Button onClick={handleCloseSplit} disabled={splitting} variant="contained" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
             
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Timesheet?"}</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you absolutely sure you want to delete this timesheet? (this will delete all records to this Timesheet!)
                    </DialogContentText>

                    <TextField
                        variant="outlined"
                        autoFocus
                        multiline
                        fullWidth
                        rows={4}
                        margin="dense"
                        value={deleteReason}
                        onChange={(e)=>setDeleteReason(e.target.value)}
                        id="deleteReason"
                        label="Please provide a reason for this deletion."
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        No!
                    </Button>
                    <Button onClick={handleDeleteClick} disabled={deleteReason.length<5} variant="contained" color="primary" autoFocus>
                        Yes!
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={approveOpen}
                onClose={handleClickApproveClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.timesheetData?.approved ? "Unapprove Timesheet #"+props.timesheetData.timesheetID+' ?' : "Approve Timesheet #"+props.timesheetData.timesheetID+' ?'}</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {props.timesheetData?.approved ? 'unapprove' : 'approve'} this timesheet?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.timesheetData?.approved ? () => handleUnapproveClick()  : () => handleApproveClick()} variant="contained" color="primary" autoFocus>
                        {props.timesheetData?.approved ? 'Unapprove' : 'Approve'}
                    </Button>
                    <Button onClick={handleClickApproveClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={approvePayrollOpen}
                onClose={handleClickApprovePayrollClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.timesheetData?.payrolled ? "Unapprove Timesheet #"+props.timesheetData.timesheetID+' for Payroll?' : "Approve Timesheet #"+props.timesheetData.timesheetID+' for Payroll?'}</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {props.timesheetData?.payrolled ? 'unapprove' : 'approve'} this timesheet for Payroll?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.timesheetData?.payrolled ? () => handleUnapproveClickPayroll()  : () => handleApproveClickPayroll()} variant="contained" color="primary" autoFocus>
                        {props.timesheetData?.payrolled ? 'Unapprove' : 'Approve'}
                    </Button>
                    <Button onClick={handleClickApproveClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={4}>
                {props.timesheetData.timesheetID 
                ?
                <Typography variant='h6'>
                    Timesheet #{props.timesheetData.timesheetID} 
                    {/* { _.isUndefined(props.timesheetData.timesheetType) || props.timesheetData.timesheetType === '' 
                    ? 
                        <Chip variant='default' color="primary" label={<b>NO TYPE</b>} /> 
                    :
                        <Chip variant='default' label={<b>{props.timesheetData.timesheetType.toUpperCase()}</b>} />} */}

                    <FormControl>                       
                        <Select
                            style={{marginLeft: '10px'}}
                            native
                            value={props.timesheetData.timesheetType}
                            onChange={(event) => { props.updateTimesheetField({ 'timesheetType': event.target.value }); }}
                        >
                            <option value={"Construction"}>Construction</option>
                            <option value={"Grain"}>Grain</option>
                            <option value={"Gravel"}>Gravel</option>
                            <option value={"Office"}>Office</option>
                            <option value={"Shop"}>Shop</option>
                            <option value={"Snow"}>Snow</option>
                        </Select>
                    </FormControl>
                </Typography>

                : 
                <Typography variant='h6'>
                    Timesheet in Progress { _.isUndefined(props.timesheetData.timesheetType) || props.timesheetData.timesheetType === '' 
                    ? 
                        <Chip variant='default' color="primary" label={<b>NO TYPE</b>} /> 
                    :
                        <Chip variant='default' label={<b>{props.timesheetData.timesheetType.toUpperCase()}</b>} /> }
                </Typography>}

                <Grid item xs={12}>
                    {props.userLoading ? <Skeleton variant="text" width={100} /> : <React.Fragment><Button onClick={() => handleEmployeeClick()} style={{margin:'5px'}} variant="outlined" color="secondary"> <Typography style={{fontStyle:'bold'}}>{props.userData}</Typography></Button> 
                    </React.Fragment>}
                </Grid>
                
                <Grid item xs={12}>
                    <Chip variant='outlined' label={_.isUndefined(props.covidData.symptoms) ? 'Symptoms Unknown' : !props.covidData.symptoms ? 'No Symptoms' : props.covidData.error} onClick={props.viewCovidData} />
                </Grid>
            </Grid>

            <Grid container direction='row' spacing={2} item xs={8} >
                <Grid item xs={12} sm={3} >
                    <Button fullWidth variant='contained' size='small' onClick={handlePrint}>Print</Button>
                </Grid>
                {props.timesheetData.submitted 
                ?
                    <Grid item xs={12} sm={3}>
                        {props.unsubmitting 
                            ?
                            <Button fullWidth variant='contained' size='small' disabled>Unsubmitting...</Button>
                            : 
                            <Button fullWidth variant='contained' size='small' 
                            disabled={props.timesheetData.approved || props.timesheetData.payrolled}
                            //disabled={!roles.includes('Office Timesheets') && !roles.includes('Tech Timesheets') && !roles.includes('Driver Timesheets') && !roles.includes('Shop Timesheets') } 
                            onClick={props.unsubmit}>Unsubmit</Button>
                        }
                    </Grid> 
                :
                    <Grid item xs={12} sm={3}>
                        {props.submitting 
                            ?
                            <Button fullWidth variant='contained' size='small' disabled>Saving...</Button>
                            : 
                            <Button fullWidth variant='contained' size='small' disabled={props.timesheetData.approved || props.timesheetData.payrolled}
                             onClick={props.submitSheet}>Submit</Button>
                        }
                    </Grid>
                }
                {/*
                <Grid item xs={12} sm={4} >
                    {props.saving ?
                        <Button fullWidth variant='contained' size='small' disabled>Saving...</Button>
                        : <Button fullWidth variant='contained' size='small'   onClick={props.saveTimesheet}>Save</Button>
                    }
                </Grid>*/}

                {props.timesheetData?.approved && !props.timesheetData?.payrolled && roles.includes('Approve Payroll Timesheets') && props.timesheetData.submitted ?
                <Grid item xs={12} sm={3} >
                    <Button fullWidth variant='contained' color={'secondary'} size='small' style={(props.timesheetData?.payrolled)
                    ? null : {color:'white', backgroundColor:'green'}} onClick={handleClickApprovePayrollOpen}  
                    startIcon={!props.timesheetData?.payrolled ? <ThumbUpIcon/> : <ThumbDownIcon/>}>
                        {props.timesheetData?.payrolled ? 'Unapprove Payroll' : 'Approve Payroll'}
                    </Button>
                </Grid>
                :null}

                {props.timesheetData?.approved !== null && ((props.timesheetData?.timesheetType === 'Office' && roles.includes('Approve Office Timesheets')) || (props.timesheetData?.timesheetType === 'Construction' && roles.includes('Approve Construction Timesheets'))
                                    || (props.timesheetData?.timesheetType === 'Gravel' && roles.includes('Approve Gravel Timesheets')) || (props.timesheetData?.timesheetType === 'Shop' && roles.includes('Approve Shop Timesheets')) || (props.timesheetData?.timesheetType === 'Snow' && roles.includes('Approve Snow Timesheets')) )&&
                 props.timesheetData.submitted 
                 ? 
                 <Grid item xs={12} sm={3} >
                    <Button fullWidth variant='contained' color={'secondary'} size='small' style={(props.timesheetData?.approved)
                    ? null : {color:'white', backgroundColor:'green'}} onClick={handleClickApproveOpen}  
                    startIcon={!props.timesheetData?.approved ? <ThumbUpIcon/> : <ThumbDownIcon/>}>
                        {props.timesheetData?.approved ? 'Unapprove' : 'Approve'}
                    </Button>
                </Grid> 
                : null 
                }

                <Grid item xs={12} sm={3} >
                    <Button fullWidth variant='contained' size='small' style={(props.timesheetData?.approved)
                    ? null : {color:'white', backgroundColor:'red'}} onClick={handleClickOpen} 
                    disabled={(props.timesheetData?.approved)
                         } 
                    startIcon={<DeleteIcon />}>Delete</Button>
                </Grid>

            </Grid>

            <Grid container>
                <Grid item>
                    <Typography>{props.errorMessage}</Typography>
                </Grid>
            </Grid>
            
            {moment() < moment(props.timesheetData.outTime).add(1, 'hours') ?
            <Grid item xs={12} style={{border: '2px solid orange', display: 'flex', justifyContent: 'center', borderRadius: '5px',}}>
                <Typography style={{display: 'flex', justifyContent: 'center', color: 'orangered', fontSize: '20px', fontWeight: '500' }}>
                    {" This timesheet can still be modified by the employee until " + moment(props.timesheetData.outTime).add(1, 'hours').format('hh:mm a') + "."}
                </Typography>
            </Grid>
            : 
            null
            }

            {!props.isTimesheetOnSameDate() ?
            <Grid item xs={12} style={{border: '1px solid #FFBF00', display: 'flex', justifyContent: 'center', borderRadius: '5px',}}>
                <Typography style={{display: 'flex', justifyContent: 'center', color: 'orangered', fontSize: '20px', fontWeight: '500', marginRight:'10px' }}>
                    {"This Timesheet's In Time & Out Time are on 2 different Dates. Split into 2 Timesheets?"}
                </Typography>
                <Button
                    variant="contained" 
                    color="primary"
                    onClick={()=>handleOpenSplit()}
                >Split Timesheet</Button>
            </Grid>
            : 
            null
            }
            
        
        </Grid >

        <Slide direction="down" in={alertOpen} style={{ position: 'sticky', width: 'calc(100% - 32px)' }}><Alert variant="filled" severity={setting === 'unapprove' ? "error" : "success"}>
            Timesheet #{props.timesheetData.timesheetID} {setting === 'unapprove' ? 'Unapproved' : 'Approved!'}, loading next timesheet...
    </Alert></Slide></React.Fragment>
    )
}
export default withRouter(TimesheetHeader)