import React, { useState, useCallback, } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { firestore, functions, storage } from '../../../firebase/firebase';
import { Font, Page, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import _ from 'lodash';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab'
import useAsyncError from '../../components/UI/AsyncError/AsyncError';

import CompletionModal from '../../components/Invoice/PDFs/PDFComponents/CompletionModal';
import InvoicePDF from '../../components/Invoice/PDFs/InvoicePDF';

import PDFContent from '../../components/Invoice/PDFs/PDFComponents/PDFContent';
import PDFHeader from '../../components/Invoice/PDFs/PDFComponents/PDFHeader';
import PDFInvoiceInfo from '../../components/Invoice/PDFs/PDFComponents/PDFInvoiceInfo';
import PDFSummary from '../../components/Invoice/PDFs/PDFComponents/PDFSummary';

const useStyles = makeStyles((theme) => ({
    tableText: {
        fontSize: 14,
        //marginBottom: '1px',
        //padding: '20px',
        paddingRight: '15px',
        paddingLeft: '15px',
    },
    tableTextBold: {
        fontSize: 10,
        //marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        //marginTop: '5px'
    },
    tableTextBoldUnderline: {
        fontSize: 24,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        paddingRight: '15px',
        paddingLeft: '15px',
        //padding: '15px',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    tableText: {
        fontSize: 10,
        marginBottom: '1px',
        textAlign: 'center',
    },
    tableTextBold: {
        fontSize: 10,
        marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginTop: '5px',
        textAlign: 'center',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
        textAlign: 'center',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    instructionHeader: {
        paddingBottom: '5px',
        fontSize: 12,
        color: 'blue',
    },
});

function InvoicePrint(props) {
    const classes = useStyles();
    const throwAsyncError = useAsyncError();
    const getInvoiceNumber = functions.httpsCallable('generateInvoiceNumber');

    const [doc, setDoc] = useState(null)
    const [ticketData] = useState(JSON.parse(props.location.state.ticketData))
    const [invoiceDetails] = useState(JSON.parse(props.location.state.invoiceDetails))
    const [invoiceFilter] = useState(JSON.parse(props.location.state.invoiceFilter))
    const [equipment] = useState(JSON.parse(props.location.state.equipment))
    //const [rates] = useState(JSON.parse(props.location.state.rates))
    const [currentCustomer, setCurrentCustomer] = useState(null)
    const [completionModalOpen, setCompletionModalOpen] = useState(false)
    // 0 - 5 to represent each part of completion process 
    const [completionProgress, setCompletionProgress] = useState(0)
    const [loading, setLoading] = useState(true)
    const [additionalAttatchments, setAdditionalAttatchments] = useState([])
    const [columns, setColumns] = useState(
        invoiceDetails.combinedMaterialCartageSales
        ? [ {header: 'Date', width: 0, field: 'date'}, 
            {header: 'Unit #', width: 0, field: 'unitNumber'}, 
            {header: 'GeeTee #', width: 0, field: 'orderId'},
            {header: 'Ticket #', width: 0, field: 'ticket'}, 
            {header: 'Material', width: 0, field: 'material'}, 
            {header: 'Origin', width: 0, field: 'from'}, 
            {header: 'Destination', width: 0, field: 'to'}, 
            {header: 'Shipped', width: 0, field: 'cartageSaleUnitsWPer'}, 
            {header: 'Rate', width: 0, field: 'rateTotal'}, 
            {header: 'Sales', width: 0, field: 'cartageSalesTotal'}, ] 

        : [ {header: 'Date', width: 0, field: 'date'}, 
            {header: 'Unit', width: 0, field: 'unitNumber'}, 
            {header: 'GeeTee #', width: 0, field: 'orderId'},
            {header: 'Ticket #', width: 0, field: 'ticket'}, 
            {header: 'Material', width: 0, field: 'material'},
            {header: 'Origin', width: 0, field: 'from'}, 
            {header: 'Destination', width: 0, field: 'to'}, 
            //{header: 'Units', width: 0, field: 'materialUnits'}, 
            //{header: 'Rate', width: 0, field: 'materialSalesRate'}, 
            //{header: 'Material', width: 0, field: 'materialSalesTotal'}, 
            {header: 'Units', width: 0, field: 'cartageSaleUnits'}, 
            {header: 'Rate', width: 0, field: 'cartageSaleRate'}, 
            //{header: 'Cartage', width: 0, field: 'cartageSalesTotal'}, 
            {header: 'Total', width: 0, field: 'total'},]
    )

    const handleGetInitialInfo = (updatedColumns) => {
        firestore.collection('customers').doc(JSON.parse(props.location.state.customers).pop().docId).get()
        .then((doc) => {
            setCurrentCustomer({...doc.data(),'docId': doc.id,})
            setDoc(generateDoc(doc.data(), invoiceDetails, ticketData, null, updatedColumns))
            setLoading(false)
        }).catch((e) => {
            throwAsyncError(new Error(e.message, e));
        });
    }

    const handleCompleteInvoice = async (subject, message, skipEmail) => {
        try{
            setCompletionProgress(1)
            await getInvoiceNumber().then(async (res) => {
                if(res.data === null){ throw new Error('Failed to retrieve invoice number.') }
                setCompletionProgress(2)
                const generatedDoc = generateDoc(currentCustomer, invoiceDetails, ticketData, res.data, columns)
                const blobPdf = await pdf(generatedDoc).toBlob();
                const storageRef = storage.ref();
                const pdfRef = storageRef.child('Invoices/' + moment().valueOf() + '-' + res.data);
                    await pdfRef.put(blobPdf).then(async () => {
                    await pdfRef.getDownloadURL().then((url) => {
                        setCompletionProgress(3)
                        let batch = firestore.batch();

                        ticketData.forEach((ticket) => {
                            if(_.isEmpty(ticket.invoicePayDate)){
                                const docRef = firestore.collection('deliveries').doc(ticket.docId);
                                batch.update(docRef, { invoicePayDate: moment().valueOf() });
                            }
                        })
                        
                        let invoiceDBRef = firestore.collection('invoices').doc()
                        let customerSet = new Set(JSON.parse(props.location.state.customers).map((customer) => ( customer.docId )))
                        let invoiceDoc = {
                            invoiceURL: url,
                            invoiceId: res.data,
                            tickets: ticketData.map((ticket) => ( ticket.docId )),
                            customers:  [...customerSet],
                            createdOn: moment().valueOf(),
                        }

                        batch.set(invoiceDBRef, invoiceDoc);
                        batch.commit()
                        .then(async () => { 
                            if(!skipEmail){
                                setCompletionProgress(4)
                                await handleEmailInvoice(blobPdf, subject, message).then((res) =>{
                                    if(!res.data.success){ throw new Error('Email was not delivered, error occured.') }
                                    setCompletionProgress(5)
                                    setTimeout(() => {
                                        props.history.replace({
                                            pathname: '/dashboard/invoicing/form', 
                                            search: "?" + new URLSearchParams({ docId: invoiceDBRef.id }).toString(), 
                                        })
                                    }, 1000)
                                })
                            }
                            else{
                                setCompletionProgress(5)
                                setTimeout(() => {
                                    props.history.replace({
                                        pathname: '/dashboard/invoicing/form', 
                                        search: "?" + new URLSearchParams({ docId: invoiceDBRef.id }).toString(), 
                                    })
                                }, 1000)
                            }
                        })
                    })
                })
            })
        }
        catch(e) { 
            // maybe handle errors in modal? 
            // seperate each function individually, and on failure allow current function to contiue from completion point - resume from there?

            throwAsyncError(new Error(e.message, e));
        }
    }

    const fileToNodeAttachment = (file, name) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(
                {filename: name, content: reader.result.substr(reader.result.indexOf(',') + 1), encoding: 'base64'}
                //reader.result.substr(reader.result.indexOf(',') + 1)
            );
          };
        });
    };

    const handleEmailInvoice = async (blob, subject, message) => {
        return new Promise(async (resolve, reject) => {
            let promises = []
            promises.push(fileToNodeAttachment(blob, `Geetee-Invoice-${moment().valueOf()}.pdf`))
            additionalAttatchments?.forEach(async (attachment) => { promises.push(fileToNodeAttachment(attachment, attachment.name)) })

            Promise.all(promises).then(async (res) => {
                let emailList = currentCustomer.contacts.filter(x=> x.invoiceContact || x.ccToInvoice).map((contact) => { return contact.email }).join(', ')

                const sendInvoiceEmail = functions.httpsCallable('sendCustomerInvoiceEmail');
                await sendInvoiceEmail({
                    subject: subject,
                    message: message,
                    attachments: res,
                    customerEmail: emailList,
                    sandbox: process.env.NODE_ENV === 'development',
                })
                .then((cloudCallback) => {
                    resolve(cloudCallback)
                })
                .catch((error) => {
                    console.warn(error)
                    reject(null)
                });
            })
        });
    }

    const handleAddFile = (fileArray) => {
        //file validation ??? WHAT SHOULD WE ALLOW ???
        let newAttachments = [...additionalAttatchments, ...fileArray]
        setAdditionalAttatchments(newAttachments)
    }

    const handleRemoveFile = (index) => {
        let newAttatchments = additionalAttatchments.filter((att, i) => i !== index)
        setAdditionalAttatchments(newAttatchments)
    }

    const naivgateToCustomer =(customerDocId) => {
        props.history.push({
            pathname: '/dashboard/customers/form',
            search: "?" + new URLSearchParams({ 'docId': customerDocId }).toString(),
        })
    }

    //callback for when invisible table renders on dom, sets column widths & initalizes rest of loading proccess
    const invisTableCallback = useCallback((el) => {
        if (el !== null) {
            let columnsContainer = _.cloneDeep(columns)
            let columnArray = [...el?.tHead?.children[0]?.children];
            let totalContainer = columnArray.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.offsetWidth)), 0,)

            for (const [index, property] of columnArray.entries()) {
                if(columnsContainer.length !== columnArray.length){ throw new Error('columns length does not match.') }
                columnsContainer[index].width = Number((property.offsetWidth / totalContainer) * 100)
            }

            handleGetInitialInfo(columnsContainer)
            setColumns(columnsContainer)
            el.style.display = 'none';
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateDoc = (customer, invoiceDetails, ticketData, invoiceNumber, columns) => {
        return (
            <Document>
                <Page size="A4" style={styles.page} wrap orientation={'landscape'}> 

                <PDFHeader />

                <PDFInvoiceInfo 
                    customer={customer}
                    invoiceDetails={invoiceDetails}
                    invoiceFilter={invoiceFilter}
                    ticketData={ticketData}
                    invoiceNumber={invoiceNumber}
                />

                <PDFContent
                    columns={columns}
                    customer={customer}
                    ticketData={ticketData}
                    invoiceDetails={invoiceDetails}
                />

                <PDFSummary 
                    ticketData={ticketData}
                    invoiceDetails={invoiceDetails}
                />

                </Page>
            </Document>
        )
    }

    return (
        <>
            {/* complete modal */}
            <CompletionModal 
                open={completionModalOpen}
                setOpen={setCompletionModalOpen}
                confirmFunction={handleCompleteInvoice}
                customer={currentCustomer}
                completionProgress={completionProgress}
                invoiceFilter={invoiceFilter}

                ticketData={ticketData}
                invoiceDetails={invoiceDetails}
                additionalAttatchments={additionalAttatchments}
                handleAddFile={handleAddFile}
                handleRemoveFile={handleRemoveFile}
            />

            {/* page contents */}
            {loading
            ?
                <div style={{marginTop: '80px'}}>
                    <Skeleton variant='rect' width={'60vw'} height={'60vh'} style={{margin: 'auto'}}/>
                </div>
            :
            <>
                <InvoicePDF 
                    naivgateToCustomer={naivgateToCustomer}
                    customer={currentCustomer}
                    setCompletionModalOpen={setCompletionModalOpen} 
                    myDoc={doc}
                />
            </>
            }

            {/* invisible table used for css structure  */}
            <table style={{width: '1000px', margin: 'auto', visibility: 'hidden'}} ref={invisTableCallback} >
                <thead>
                    <tr>
                        {columns.map((column, columnIndex) => (
                            <td key={columnIndex} className={classes.tableTextBoldUnderline}>
                                {column.header}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {ticketData.map((ticket, ticketIndex) => (
                    <tr key={ticketIndex}>
                    {columns.map((column, columnIndex) => {
                            switch(column.field) {
                                //ticket fields
                                case 'date':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {moment(ticket.date).format('DD-MMM-YYYY')} </td>
                                case 'unitNumber':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.units.map((unit) => { return ( equipment?.find(x=> x?.docId === unit)?.unitNumber ) })} </td>                        
                                case 'ticket':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.ticket} </td>
                                case 'from':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.from} </td>
                                case 'to':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.to} </td>
                                case 'orderId':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.orderId} </td>
                                case 'material':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.material} </td>
                                case 'materialUnits':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {Number(ticket.materialUnits).toFixed(3)} </td>                                    
                                case 'materialSalesRate':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.materialSalesRate).toFixed(2)}`} </td>
                                case 'materialSalesTotal':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.materialSalesTotal).toFixed(2)}`} </td>                                    
                                case 'cartageSaleUnitsWPer':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`${Number(ticket.cartageSaleUnits).toFixed(3)} ${ticket.cartageSalesPer || ''}`} </td>
                                case 'cartageSaleUnits':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {Number(ticket.cartageSaleUnits).toFixed(3)} </td>
                                case 'cartageSaleRate':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.cartageSaleRate).toFixed(2)}`} </td>
                                case 'cartageSalesTotal':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.cartageSalesTotal).toFixed(2)}`} </td>
                                case 'rateTotal':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex} > {`$${Number(Number(ticket.cartageSaleRate || 0) + Number(ticket.materialSalesRate || 0)).toFixed(2)}`} </td>   
                                case 'total':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(Number(ticket.materialSalesTotal || 0) + Number(ticket.cartageSalesTotal || 0)).toFixed(2)}`} </td>       
                                default:
                                    console.warn('Ticket Field not available within render switch.')  
                                    return null;
                            }
                    })}
                    </tr>
                ))}
                {/* totals */}
                <tr>
                    {columns.map((column, columnIndex) => {
                        switch(column.field) {
                            case 'date':
                                return <td className={classes.tableText} key={columnIndex} />
                            case 'unitNumber':
                                return <td className={classes.tableText} key={columnIndex} />       
                            case 'ticket':
                                return <td className={classes.tableText} key={columnIndex} />
                            case 'material':
                                return <td className={classes.tableText} key={columnIndex} />
                            case 'orderId':
                                return <td className={classes.tableText} key={columnIndex} />       
                            case 'to':
                                return <td className={classes.tableText} key={columnIndex} />
                            case 'from':
                                return <td className={classes.tableText} key={columnIndex} />
                            case 'materialUnits':
                                return <td className={classes.tableTextBold} key={columnIndex}> {ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.materialUnits)).toFixed(3), 0,)} </td>                                    
                            case 'materialSalesRate':
                                return <td className={classes.tableTextBold} key={columnIndex} />
                            case 'materialSalesTotal':
                                return <td className={classes.tableTextBold} key={columnIndex}> {`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.materialSalesTotal)).toFixed(2), 0,)}`} </td>                                    
                            case 'cartageSaleUnitsWPer':
                                return <td className={classes.tableTextBold} key={columnIndex}> {ticketData.reduce((accumulator, currentValue) => Number(Number(accumulator) + Number(currentValue.cartageSaleUnits)).toFixed(3), 0,)} </td>
                            case 'cartageSaleUnits':
                                return <td className={classes.tableTextBold} key={columnIndex}> {ticketData.reduce((accumulator, currentValue) => Number(Number(accumulator) + Number(currentValue.cartageSaleUnits)).toFixed(3), 0,)} </td>
                            case 'cartageSaleRate':
                                return <td className={classes.tableTextBold} key={columnIndex} />
                            case 'cartageSalesTotal':
                                return <td className={classes.tableTextBold} key={columnIndex}>{`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.cartageSalesTotal)).toFixed(2), 0,)}`} </td>
                            case 'rateTotal':
                                return <td className={classes.tableTextBold} key={columnIndex} />
                            case 'total':
                                return <td className={classes.tableTextBold} key={columnIndex}>{`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + (Number(currentValue.materialSalesTotal || 0) + Number(currentValue.cartageSalesTotal || 0))).toFixed(2), 0,)}`}</td>     
                            default:
                                console.warn('Total Field not available within render switch.')  
                                return null;
                        }
                    })}
                </tr>
                </tbody>
            </table>
        </>
    )
}

export default withRouter(InvoicePrint)