import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
//import InlineMediaViewer from '../../UI/MediaViewer/InlineMediaViewer'
import ReportTable from '../../Reports/ReportTable/ReportTable'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TesseractImage from './TesseractImage';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import _ from 'lodash';
import TimerIcon from '@material-ui/icons/Timer';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        display:'flex',
        alignItems:'center',
        height: '100%',
        marginRight:'10px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    backButton: {
        margin: theme.spacing(2),
    },
    cardHeader: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        borderTopRightRadius: '16px',
        borderTopLeftRadius: '16px',
        borderBottom: '1px solid grey',
        maxWidth: '792px',
    },
    root: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        borderTopRightRadius: '16px',
        borderTopLeftRadius: '16px',

        [theme.breakpoints.down('sm')]: {
            margin: 0, 
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    },
}));

const DeliveryCard = (props) => {

    const classes=useStyles();
    let dataCard = null;

    useEffect(() => {

    }, []);

    const handleNextDelivery = () => {
        let index = props.location.state.deliveryList.findIndex(x => x.docId === props.deliveryData.docId)
        if(index < props.location.state.deliveryList.length - 1){
            props.history.push({
                pathname:`/dashboard/reporting/timesheets/delivery`,
                search: `?docId=${props.location.state.deliveryList[index + 1].docId}`,
                state: {deliveryList: props.location.state.deliveryList}
            })
        }
    }

    const handlePrevDelivery = () => {
        let index = props.location.state.deliveryList.findIndex(x => x.docId === props.deliveryData.docId)
        if(index > 0){
            props.history.push({
                pathname:`/dashboard/reporting/timesheets/delivery`,
                search: `?docId=${props.location.state.deliveryList[index - 1].docId}`,
                state: {deliveryList: props.location.state.deliveryList}
            })
        }
    }

    if (!_.isUndefined(props.deliveryData)) {
        dataCard = (
        <Grid className={classes.content}>
            <Grid className={classes.backButton}>
                <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => 
                    props?.location?.state && props.location.state.deliveryList.length ? 
                    props.history.push({
                        pathname: '/dashboard/reporting/timesheets',
                        search: "?" + new URLSearchParams({ docId: props.deliveryData.timesheetID }).toString()})
                    : props.history.goBack()
                    
                    }>Back</Button>
                {props?.location?.state && props.location.state.deliveryList.length > 1 && <React.Fragment>
                    <Button variant="contained" color="secondary" disabled={
                        props.location.state.deliveryList.findIndex(x => x.docId === props.deliveryData.docId) === 0
                    } style={{marginLeft:'5px'}} onClick={() => handlePrevDelivery()} startIcon={<ArrowBackIcon/>}>Previous</Button>
                    <Button variant="contained" color="secondary" disabled={
                        props.location.state.deliveryList.findIndex(x => x.docId === props.deliveryData.docId) === props.location.state.deliveryList.length - 1
                    } style={{marginLeft:'5px'}} onClick={() => handleNextDelivery()} startIcon={<ArrowForwardIcon/>}>Next</Button>
                    </React.Fragment>
                }
            </Grid>
            <Paper className={classes.root}>
                <Grid container className={classes.cardHeader}>
                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant='h4'><LocalShippingIcon style={{marginRight:'10px'}}/>Delivery</Typography>
                            </Grid>
                            <Grid item xs={6} style={{display:'flex', justifyContent:'flex-end'}}>
                                {props.deliveryData.timesheetID && props.deliveryData.timesheetID !== '' ?<Button 
                                    variant='contained' 
                                    color='secondary'
                                    startIcon={<TimerIcon />}
                                    onClick={()=> {
                                        props.history.push({
                                            pathname: '/dashboard/reporting/timesheets',
                                            search: "?" + new URLSearchParams({ docId: props.deliveryData.timesheetID }).toString(),
                                        }) }
                                    }
                                >
                                    View Related Timesheet
                                </Button>:null}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant='h5'>Ticket #{props.deliveryData.ticket}</Typography>
                            <Typography variant='h5'>{props.userData.displayName}</Typography>
                            <Typography variant='h6'>{props.deliveryData?.date}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.content} spacing={1}>
                    <Grid item xs={12}>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.card}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Typography variant='h6'>Customer</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField 
                                        fullWidth 
                                        name='customer' 
                                        value={props.deliveryData.customer}
                                        onChange={(e) => props.handleDeliveryChange(e, 'customer')}
                                    />
                                </Grid>
                                <React.Fragment>
                                <Grid item xs={2}>
                                    <Typography variant='h6'>Address</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField 
                                        fullWidth 
                                        name='customerAddress' 
                                        value={props.deliveryData.customerAddress}
                                        onChange={(e) => props.handleDeliveryChange(e, 'customerAddress')}
                                    />
                                </Grid></React.Fragment>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className={classes.row}>
                                        <Typography variant='body1'>From</Typography>
                                    </Grid>
                                    <Grid item xs={10} className={classes.row}>
                                        <TextField 
                                            fullWidth 
                                            name='from' 
                                            value={props.deliveryData.from}
                                            onChange={(e) => props.handleDeliveryChange(e, 'from')}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className={classes.row}>
                                        <Typography variant='body1'>To</Typography>
                                    </Grid>
                                    <Grid item xs={10} className={classes.row}>
                                        <TextField 
                                            fullWidth 
                                            name='to' 
                                            value={props.deliveryData.to}
                                            onChange={(e) => props.handleDeliveryChange(e, 'to')}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className={classes.row}>
                                        <Typography variant='body1'>Material</Typography>
                                    </Grid>
                                    <Grid item xs={9} className={classes.row}>
                                        <TextField 
                                            fullWidth 
                                            name='material' 
                                            value={props.deliveryData.material}
                                            onChange={(e) => props.handleDeliveryChange(e, 'material')}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={classes.row}>
                                        <Typography variant='body1'>Quantity</Typography>
                                    </Grid>
                                    <Grid item xs={9} className={classes.row}>
                                        <TextField 
                                            fullWidth 
                                            name='quantity' 
                                            type="number"
                                            value={Number(props.deliveryData.quantity)}
                                            onChange={(e) => props.setDeliveryData({...props.deliveryData, quantity: Number(e.target.value)})}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    {props.deliveryData.notes && props.deliveryData?.notes !=="" ?
                        <Grid item xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.row}>
                                        <Typography variant='body1'><strong>Notes</strong></Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.row}>
                                        <TextField 
                                            fullWidth 
                                            multiline
                                            variant="outlined"
                                            minRows={3}
                                            name='notes' 
                                            value={props.deliveryData.notes}
                                            onChange={(e) => props.handleDeliveryChange(e, 'notes')}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 
                    : null}

                    {props.unitData.length > 0 ? 
                        <Grid item md={12} xs={12} className={classes.root}>
                            <ReportTable
                                columns = {[
                                    { title: 'Unit Number', field: 'unitNumber' },
                                    { title: 'Trailer ID', field: 'trailerID' },
                                    { title: 'Fuel Added', field: 'fuelAdded' },
                                    { title: 'Greased', render: rowData => rowData.greased ? "Yes" : "No" },
                                    { title: 'Net Mileage', render: rowData => (rowData.mileageOut - rowData.mileageIn).toFixed(2) },
                                    { title: 'Defects', render: rowData => rowData.defectID !== "" ? "Yes" : "No" },
                                ]}
                                data = {props.unitData}
                                title = {"Unit Details"}
                                pathname = '/dashboard/reporting/timesheets/unit'

                            />
                        </Grid>
                    : null}
                    {props.deliveryData.photoURLs && props.deliveryData.photoURLs.length > 0 ?
                    <Grid container xs={12}>
                        <TesseractImage deliveryData={props.deliveryData} photoUrls={props.deliveryData.photoURLs} />
                    </Grid>:null}
                </Grid>
            </Paper>
        </Grid>
    )}

    return (
        <div className={classes.content}>
            {dataCard}
        </div>
    )
}

export default withRouter(DeliveryCard)